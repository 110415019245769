<script setup lang="ts">
// explicit import is needed when using the function in the template for some reason
import { appendTrailingSlash, getArticlePrimaryImage } from '#imports';

interface IProps {
    article: StoryblokStoryInterface<ArticlePage001>;
    isFeatured?: boolean;
    readTimeText: string;
    showCategoryEyebrow?: boolean;
    startingHeaderLevel?: number;
    writtenByAuthorText: string;
}
const props = withDefaults(defineProps<IProps>(), {
    isFeatured: false,
    showCategoryEyebrow: true,
    startingHeaderLevel: 4,
});

const articlePrimaryImage = computed(() => {
    return getArticlePrimaryImage(props.article);
});

const articleHeader = computed(() => props.article.content?.articleEeat[0]?.header);

const publishDate = computed(() => getArticleLastUpdatedDate(props.article));

// only render the eyebrow header if the toggle is on and we actually have a category to display
const showEyebrow = computed(() => props.showCategoryEyebrow && props.article.content?.articleEeat?.[0]?.category);
</script>

<template>
    <div class="LandingFeaturedArticle p-150 p-lg-0 position-relative">
        <component
            :is="`h${startingHeaderLevel}`"
            v-if="showEyebrow"
            class="eyebrow h3 mb-lg-50"
            :class="isFeatured ? 'mb-100' : 'mb-50'">
            {{ article.content?.articleEeat[0]?.category }}
        </component>
        <component
            :is="`h${showEyebrow ? startingHeaderLevel + 1 : startingHeaderLevel}`"
            class="mb-100 mb-lg-50"
            :class="isFeatured ? 'h3' : 'font-size-lg-200'">
            <a
                v-if="article.full_slug"
                class="d-block"
                :href="appendTrailingSlash(article.full_slug)">
                {{ articleHeader }}
            </a>
        </component>
        <p
            class="font-size-75 mb-50"
            :class="isFeatured ? 'mb-lg-50' : 'mb-lg-0'">
            <token-replace
                v-if="article.content?.articleEeat[0]?.authors.length"
                :string="writtenByAuthorText">
                <template #authors>
                    <span
                        v-for="(author, index) in article.content?.articleEeat[0]?.authors"
                        :key="author.id">
                        <span v-if="index > 0">, </span>
                        <a
                            v-if="author.full_slug"
                            :href="appendTrailingSlash(author.full_slug)"
                            >{{ author.content?.authorInfo?.[0]?.name }}</a
                        >
                    </span>
                </template>
            </token-replace>
            <span class="d-sm-inline">
                |
                <span class="text-nowrap">
                    <!-- @vue-expect-error todo -->
                    {{ localizeDate(publishDate, 'en-US', { dateStyle: 'medium' }) }}
                </span>
            </span>
        </p>
        <p class="LandingFeaturedArticle-readTime font-size-sm mb-0 text-muted">
            <token-replace :string="readTimeText">
                <template #num>
                    {{ article.content?.articleEeat[0]?.readTimeMinutes }}
                </template>
            </token-replace>
        </p>
        <a
            v-if="isFeatured && article.full_slug"
            :aria-label="articleHeader"
            class="d-block mt-100"
            :href="appendTrailingSlash(article.full_slug)">
            <storyblok-image
                v-if="articlePrimaryImage"
                :alt="articlePrimaryImage.alt"
                sizes="xl:775px lg:690px md:510px sm:384px"
                class="LandingFeaturedArticle-graphic d-block w-100"
                :class="{ 'LandingFeaturedArticle-graphic--featured': isFeatured }"
                :src="articlePrimaryImage.filename" />
        </a>
    </div>
</template>

<style lang="scss" scoped>
@use '@energysage/es-ds-styles/scss/variables';
@use '@energysage/es-ds-styles/scss/mixins/breakpoints';

.LandingFeaturedArticle {
    /* only on mobile */
    background-color: variables.$white;
    border: 2px solid variables.$gray-100;
    border-radius: variables.$border-radius;
    overflow: hidden;

    &-graphic {
        height: auto;

        &--featured {
            border-radius: variables.$border-radius;
        }
    }
}
@include breakpoints.media-breakpoint-up(lg) {
    .LandingFeaturedArticle {
        background-color: transparent;
        border-radius: 0;
        border-style: none;
    }
}
</style>
